.header {
  background-color: $black;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-family: $font-alt;
  color: $white;
  padding: 125px 0 70px;
  box-shadow: inset 0 0 20px black;

  h1 {
    margin: 0;
  }

  &--center {
    @extend .header;
    display: block;
    text-align: center;
    padding: 125px 0 40px;

    h2 {
      color: #B07E49;
      font-size: 21px;
    }
  }

  &--tight {
    @extend .header;
    padding: 65px 0 50px;

    h1 {
      font-size: 57px;

      &[data-glow=griffon] {
        text-shadow: 0 0 30px #0AB5E1;
      }

      &[data-glow=hellhound] {
        text-shadow: 0 0 30px #F7B662;
      }
    }

    h2 {
      font-size: 33px;
    }
  }
}
