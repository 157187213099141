/**
 * Debug mode (dev env)
 * Set debug mode to false for production
 */
/**
 * Hard reset
 */
/**
 * Reset all margin and padding.
 */
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0; }

/**
 * 1. Remove repeating backgrounds in all browsers.
 * 2. Add box sizing inheritance in all browsers.
 */
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: inherit;
  /* 2 */ }

/**
 * 1. Add text decoration inheritance in all browsers.
 * 2. Add vertical alignment inheritance in all browsers.
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */ }

/**
 * 1. Add border box sizing in all browsers.
 * 2. Add the default cursor in all browsers.
 * 3. Prevent font size adjustments after orientation changes in IE and iOS.
 */
html {
  box-sizing: border-box;
  /* 1 */
  cursor: default;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/**
 * Add the correct display in IE.
 */
article,
aside,
footer,
header,
nav,
section,
figcaption,
figure,
main {
  display: block; }

/**
 * Remove the margin and padding in all browsers.
 */
body {
  margin: 0;
  padding: 0; }

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * Remove the list style on navigation lists in all browsers.
 */
nav ol,
nav ul {
  list-style: none; }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp,
pre {
  font-family: monospace;
  /* 1 */
  font-size: 1rem;
  /* 2 */ }

/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

/*
 * Remove the text shadow on text selections.
 * 1. Restore the coloring undone by defining the text shadow.
 */
::-moz-selection {
  background-color: #B3D4FC;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none; }

::selection {
  background-color: #B3D4FC;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none; }

/*
 * Change the alignment on media elements in all browers.
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/**
 * Add the correct display in IE.
 */
audio,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/**
 * Collapse border spacing
 */
table {
  border-collapse: collapse; }

/**
 * Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  padding: 0; }

/**
 * Inherit styling in all browsers.
 */
button,
input,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none; }

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
  /* 2 */ }

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */ }

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  resize: vertical;
  /* 2 */ }

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */
details,
menu {
  display: block; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/*
 * Remove the tapping delay on clickable elements (opinionated).
 * 1. Remove the tapping delay in IE 10.
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation; }

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none; }

/**
 * Change the cursor on busy elements.
 */
[aria-busy=true] {
  cursor: progress; }

/*
 * Change the cursor on control elements.
 */
[aria-controls] {
  cursor: pointer; }

/*
 * Change the display on visually hidden accessible elements.
 */
[aria-hidden=false][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements.
 */
[aria-disabled],
[disabled] {
  cursor: not-allowed; }

/**
 * Vendors
 */
.lb-loader, .lightbox {
  text-align: center;
  line-height: 0;
  position: absolute;
  left: 0; }

body.lb-disable-scrolling {
  overflow: hidden; }

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #000;
  filter: alpha(Opacity=80);
  opacity: .8;
  display: none; }

.lightbox {
  width: 100%;
  z-index: 10000;
  font-weight: 400;
  outline: 0; }

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
  border: 4px solid #fff; }

.lightbox a img {
  border: none; }

.lb-outerContainer {
  position: relative;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  background-color: #fff; }

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-loader {
  top: 43%;
  height: 25%;
  width: 100%; }

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../images/loading.gif) no-repeat; }

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10; }

.lb-container > .nav {
  left: 0; }

.lb-nav a {
  outline: 0;
  background-image: url(data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==); }

.lb-next, .lb-prev {
  height: 100%;
  cursor: pointer;
  display: block; }

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../images/prev.png) left 48% no-repeat;
  filter: alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity .6s;
  -moz-transition: opacity .6s;
  -o-transition: opacity .6s;
  transition: opacity .6s; }

.lb-nav a.lb-prev:hover {
  filter: alpha(Opacity=100);
  opacity: 1; }

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../images/next.png) right 48% no-repeat;
  filter: alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity .6s;
  -moz-transition: opacity .6s;
  -o-transition: opacity .6s;
  transition: opacity .6s; }

.lb-nav a.lb-next:hover {
  filter: alpha(Opacity=100);
  opacity: 1; }

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-data {
  padding: 0 4px;
  color: #ccc; }

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em; }

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: 700;
  line-height: 1em; }

.lb-data .lb-caption a {
  color: #4ae; }

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999; }

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../images/close.png) top right no-repeat;
  text-align: right;
  outline: 0;
  filter: alpha(Opacity=70);
  opacity: .7;
  -webkit-transition: opacity .2s;
  -moz-transition: opacity .2s;
  -o-transition: opacity .2s;
  transition: opacity .2s; }

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: alpha(Opacity=100);
  opacity: 1; }

/**
 * Base
 */
/**
 * General
 */
/**
 * Colors general
 */
/**
 * Colors
 */
/**
 * Shadows
 */
/**
 * Buttons
 */
/**
 * Breakpoints
 */
/**
 * Fonts
 */
@font-face {
  font-family: "riffic-bold";
  src: url("fonts/iffic-bold-webfont.woff2") format("woff2"), url("fonts/riffic-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

/**
 * Animations
 */
/**
 * General functions
 */
/**
 * Color functions
 */
/**
 * Normalise
 */
body {
  background-color: #17120F;
  font-family: "Source Sans Pro", "Arial", sans-serif;
  text-align: center; }

h1, .some h2,
h2,
p,
a {
  margin: 20px auto; }

ol,
ul {
  list-style-type: none; }

h1, .some h2 {
  font-size: 46px;
  color: #FFFFFF;
  font-family: "riffic-bold", "Arial", sans-serif; }
  @media screen and (max-width: 64em) {
    h1, .some h2 {
      font-size: 40px; } }
  @media screen and (max-width: 48em) {
    h1, .some h2 {
      font-size: 36px; } }
  @media screen and (max-width: 30em) {
    h1, .some h2 {
      font-size: 30px; } }

h4 {
  color: #B07E49;
  font-size: 21px;
  font-family: "riffic-bold", "Arial", sans-serif;
  margin-bottom: 0; }

/**
 * Input placeholder
 */
/**
 * Clearfix (uses before and after) -> when HTML is not an option
 */
/**
 * Grayscale
 */
/**
 * Flex center
 */
/**
 * Reset coords to 0 when positioning
 */
/**
 * Error states
 */
/**
 * Layout
 */
/**
 * Container
 */
.container {
  max-width: 1300px;
  margin: 0 auto; }

.story-holder {
  padding: 70px 0;
  max-width: 100%; }
  @media screen and (max-width: 64em) {
    .story-holder {
      padding: 40px 0; } }
  @media screen and (max-width: 48em) {
    .story-holder {
      padding: 25px 0; } }
  @media screen and (max-width: 30em) {
    .story-holder {
      padding: 20px 0; } }
  .story-holder .top-btn {
    width: 880px;
    max-width: 100%;
    margin: -30px auto 0;
    text-align: left; }
    .story-holder .top-btn .btn--back {
      margin-top: 0; }
    .story-holder .top-btn + .frame, .story-holder .top-btn + .frame--chronicles {
      margin: 115px auto 10px; }

.header, .header--center, .header--tight {
  background-color: #120E0C;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-family: "riffic-bold", "Arial", sans-serif;
  color: #FFFFFF;
  padding: 125px 0 70px;
  box-shadow: inset 0 0 20px black; }
  .header h1, .header--center h1, .header--tight h1, .header .some h2, .some .header h2, .header--center .some h2, .some .header--center h2, .header--tight .some h2, .some .header--tight h2 {
    margin: 0; }
  .header--center {
    display: block;
    text-align: center;
    padding: 125px 0 40px; }
    .header--center h2 {
      color: #B07E49;
      font-size: 21px; }
  .header--tight {
    padding: 65px 0 50px; }
    .header--tight h1, .header--tight .some h2, .some .header--tight h2 {
      font-size: 57px; }
      .header--tight h1[data-glow=griffon], .header--tight .some h2[data-glow=griffon], .some .header--tight h2[data-glow=griffon] {
        text-shadow: 0 0 30px #0AB5E1; }
      .header--tight h1[data-glow=hellhound], .header--tight .some h2[data-glow=hellhound], .some .header--tight h2[data-glow=hellhound] {
        text-shadow: 0 0 30px #F7B662; }
    .header--tight h2 {
      font-size: 33px; }

.squads {
  background-color: #120E0C;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-family: "riffic-bold", "Arial", sans-serif;
  padding: 55px 0;
  box-shadow: inset 0 0 20px black; }
  @media screen and (max-width: 48em) {
    .squads {
      padding: 35px 0; } }
  @media screen and (max-width: 400px) {
    .squads {
      padding: 25px 0 0; } }
  .squads .squad {
    font-size: 30px;
    margin: 0;
    color: #FFFFFF;
    text-decoration: none;
    transition: all .3s; }
    @media screen and (max-width: 64em) {
      .squads .squad {
        width: 50%;
        display: none; } }
    @media screen and (max-width: 48em) {
      .squads .squad {
        font-size: 22px; } }
    @media screen and (max-width: 30em) {
      .squads .squad {
        font-size: 16px; } }
    @media screen and (max-width: 400px) {
      .squads .squad p {
        display: none; } }
    .squads .squad svg {
      transition: all .2s; }
      @media screen and (max-width: 48em) {
        .squads .squad svg {
          max-width: 80%;
          height: 280px; } }
      @media screen and (max-width: 30em) {
        .squads .squad svg {
          height: 200px; } }
    @media screen and (max-width: 64em) {
      .squads .squad-griffon {
        display: block; } }
    .squads .squad-griffon:hover {
      position: relative;
      text-shadow: 0 0 30px #0AB5E1; }
      .squads .squad-griffon:hover svg {
        transform: scale(1.1); }
    @media screen and (max-width: 64em) {
      .squads .squad-hellhound {
        display: block; } }
    .squads .squad-hellhound:hover {
      position: relative;
      text-shadow: 0 0 30px #F7B662; }
      .squads .squad-hellhound:hover svg {
        transform: scale(1.1); }
    .squads .squad p {
      margin: 0; }
    .squads .squad:nth-child(2) {
      font-size: 33px;
      width: 250px; }

.intro {
  width: 620px;
  max-width: 100%;
  color: #B17F4A;
  font-size: 20px;
  font-family: "Source Sans Pro", "Arial", sans-serif;
  font-style: italic;
  line-height: 30px;
  font-weight: 300;
  max-width: 90%; }
  @media screen and (max-width: 48em) {
    .intro {
      font-size: 17px;
      line-height: 22px; } }

.heroes {
  width: 1060px;
  max-width: 100%;
  margin: 0 auto;
  padding: 40px 0; }
  .heroes h2 {
    color: #FFFFFF;
    font-size: 46px;
    font-family: "riffic-bold", "Arial", sans-serif;
    margin-bottom: 30px; }
  .heroes .heroes-overview {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; }
    .heroes .heroes-overview .hero {
      max-width: 172px;
      flex: 0 0 172px;
      text-align: center; }
    .heroes .heroes-overview h4 {
      color: #FFFFFF;
      font-size: 21px;
      margin-top: 20px;
      margin-bottom: 0; }
    .heroes .heroes-overview p {
      color: #B07E49;
      font-size: 19px;
      margin-top: 0; }

/**
 * Components
 */
/**
 * Main menu
 */
.main-menu {
  background-color: #FFFFFF; }

.top {
  height: 65px;
  position: relative; }
  .top a {
    position: relative;
    top: 22px; }
    @media screen and (max-width: 64em) {
      .top a svg {
        width: 200px; } }
    @media screen and (max-width: 48em) {
      .top a svg {
        width: 170px; } }
  .top .logo {
    position: absolute;
    bottom: 50%; }

.frame, .frame--chronicles {
  width: 830px;
  max-width: 80%;
  margin: 145px auto 10px;
  position: relative;
  text-align: center; }
  .frame .intro, .frame--chronicles .intro {
    color: #5B3D23;
    font-size: 21px;
    line-height: 30px;
    margin-bottom: 70px;
    font-weight: 300; }
  .frame .icon-holder, .frame--chronicles .icon-holder {
    position: relative;
    z-index: 6; }
    .frame .icon-holder svg, .frame--chronicles .icon-holder svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
    .frame .icon-holder .icon-holder-opt, .frame--chronicles .icon-holder .icon-holder-opt {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 200px;
      height: 220px;
      cursor: pointer; }
      .frame .icon-holder .icon-holder-opt span, .frame--chronicles .icon-holder .icon-holder-opt span {
        display: block;
        color: #FFFFFF;
        width: 199px;
        height: 222px;
        position: relative; }
        .frame .icon-holder .icon-holder-opt span h5, .frame--chronicles .icon-holder .icon-holder-opt span h5 {
          font-size: 18px;
          position: absolute;
          z-index: 10;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 90px; }
      .frame .icon-holder .icon-holder-opt svg, .frame--chronicles .icon-holder .icon-holder-opt svg {
        width: 187px;
        height: 220px; }
      .frame .icon-holder .icon-holder-opt:nth-child(1), .frame--chronicles .icon-holder .icon-holder-opt:nth-child(1) {
        left: calc(50% - 110px); }
      .frame .icon-holder .icon-holder-opt:nth-child(2), .frame--chronicles .icon-holder .icon-holder-opt:nth-child(2) {
        left: calc(50% + 110px); }
      .frame .icon-holder .icon-holder-opt.is-active span, .frame--chronicles .icon-holder .icon-holder-opt.is-active span {
        display: none; }
  .frame .frame-content, .frame--chronicles .frame-content, .frame .frame-content--alt, .frame--chronicles .frame-content--alt, .frame .frame-content--left, .frame--chronicles .frame-content--left {
    position: relative;
    z-index: 5;
    background-color: #E3CBAA;
    padding: 115px 100px 32px;
    border: 12px solid #17120F; }
    @media screen and (max-width: 64em) {
      .frame .frame-content, .frame--chronicles .frame-content, .frame .frame-content--alt, .frame--chronicles .frame-content--alt, .frame .frame-content--left, .frame--chronicles .frame-content--left {
        padding: 115px 30px 32px; } }
    .frame .frame-content--alt, .frame--chronicles .frame-content--alt {
      text-align: center; }
      @media screen and (max-width: 64em) {
        .frame .frame-content--alt, .frame--chronicles .frame-content--alt {
          padding: 30px 20px 32px !important;
          /* stylelint-disable-line declaration-no-important */ } }
      @media screen and (max-width: 48em) {
        .frame .frame-content--alt, .frame--chronicles .frame-content--alt {
          padding: 30px 10px 32px !important;
          /* stylelint-disable-line declaration-no-important */ } }
      @media screen and (max-width: 64em) {
        .frame .frame-content--alt h2, .frame--chronicles .frame-content--alt h2 {
          font-size: 35px; } }
      @media screen and (max-width: 48em) {
        .frame .frame-content--alt h2, .frame--chronicles .frame-content--alt h2 {
          font-size: 30px; } }
    .frame .frame-content--left, .frame--chronicles .frame-content--left {
      display: none; }
      .frame .frame-content--left.is-active, .frame--chronicles .frame-content--left.is-active {
        display: block; }
      .frame .frame-content--left .img-holder, .frame--chronicles .frame-content--left .img-holder {
        margin: 70px auto;
        text-align: center; }
      .frame .frame-content--left p:not([class]), .frame--chronicles .frame-content--left p:not([class]) {
        text-align: left; }
  .frame::after, .frame--chronicles::after {
    content: "";
    position: absolute;
    top: -10px;
    left: -15px;
    right: -15px;
    bottom: -10px;
    background-color: #5B3D23;
    z-index: 4;
    border: 6px solid #875A36; }
  .frame::before, .frame--chronicles::before {
    content: "";
    position: absolute;
    top: -20px;
    left: -25px;
    right: -25px;
    bottom: -20px;
    background-color: #2C1C10;
    z-index: 3; }
  .frame p, .frame--chronicles p {
    line-height: 27px;
    font-size: 19px;
    color: #17120F;
    font-family: "Source Sans Pro", "Arial", sans-serif; }
  .frame--left > p {
    text-align: left; }
    .frame--left > p.intro {
      text-align: center; }
  .frame--chronicles {
    width: 1006px;
    margin-top: -20px; }
    .frame--chronicles .frame-content, .frame--chronicles .frame-content--alt, .frame--chronicles .frame-content--left {
      padding: 50px 50px 32px; }
    .frame--chronicles .tbd {
      pointer-events: none;
      opacity: .4; }
    .frame--chronicles h2 {
      font-size: 46px;
      color: #2C1C10;
      font-family: "riffic-bold", "Arial", sans-serif;
      margin-bottom: 30px;
      margin-top: 0; }
    .frame--chronicles li {
      text-align: left; }
      .frame--chronicles li a {
        border-radius: 12px;
        background-color: #DABB91;
        font-family: "riffic-bold", "Arial", sans-serif;
        display: block;
        margin-bottom: 7px;
        margin-top: 7px;
        color: #5B3D23;
        font-size: 21px;
        text-decoration: none;
        padding: 14px 60px 14px 20px;
        position: relative;
        transition: all .3s; }
        .frame--chronicles li a span {
          position: relative;
          z-index: 2; }
        .frame--chronicles li a .arrow {
          display: block;
          width: 55px;
          position: absolute;
          z-index: 1;
          top: 0;
          bottom: 0;
          right: 0;
          border-radius: 0 12px 12px 0;
          background: #5B3D23;
          transition: all .3s; }
          .frame--chronicles li a .arrow svg {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%); }
        .frame--chronicles li a:hover, .frame--chronicles li a:focus {
          color: #FFFFFF; }
          .frame--chronicles li a:hover .arrow, .frame--chronicles li a:focus .arrow {
            border-radius: 12px;
            width: 100%; }

.btn, .btn--primary, .btn--secondary, .btn--icon, .btn--back, .btn--next {
  padding: 13px 25px;
  font-family: "riffic-bold", "Arial", sans-serif;
  border-radius: 25px;
  border: none;
  transition: all 0.3s;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  margin-bottom: 0;
  font-size: 16px; }
  .btn-group .btn, .btn-group .btn--primary, .btn-group .btn--secondary, .btn-group .btn--icon, .btn-group .btn--back, .btn-group .btn--next {
    margin-left: 10px;
    margin-right: 10px; }
  .btn--primary {
    background-color: #2C1D10;
    color: #FFFFFF; }
    .btn--primary:hover, .btn--primary:focus, .btn--primary.is-active {
      background-color: #B07E49;
      color: #FFFFFF; }
  .btn--secondary {
    background-color: #2C1D10;
    color: #B07E49; }
    .btn--secondary:hover, .btn--secondary:focus, .btn--secondary.is-active {
      background-color: #B07E49;
      color: #FFFFFF; }
  .btn--icon, .btn--back, .btn--next {
    background-color: #2C1D10;
    color: #FFFFFF;
    position: relative; }
    .btn--icon svg, .btn--back svg, .btn--next svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
      .btn--icon svg g, .btn--back svg g, .btn--next svg g {
        stroke: #FFFFFF; }
    .btn--icon:hover, .btn--back:hover, .btn--next:hover, .btn--icon:focus, .btn--back:focus, .btn--next:focus {
      background-color: #B07E49;
      color: #FFFFFF; }
  .btn--back {
    padding-left: 55px; }
    .btn--back svg {
      left: 25px; }
  .btn--next {
    padding-right: 55px; }
    .btn--next svg {
      right: 25px; }

.footer {
  padding: 30px 0;
  background-color: #120E0C;
  box-shadow: inset 0 0 20px black; }
  .footer .footer-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    width: 1088px;
    max-width: 100%;
    margin: 0 auto; }
    @media screen and (max-width: 64em) {
      .footer .footer-content {
        display: block; } }
  .footer svg {
    width: 201px;
    height: 81px; }
  .footer a {
    font-size: 18px;
    color: #FFFFFF;
    text-decoration: none;
    font-family: "riffic-bold", "Arial", sans-serif;
    position: relative; }
    @media screen and (max-width: 64em) {
      .footer a {
        display: block; } }
    .footer a:not(:first-child)::after {
      content: "";
      position: absolute;
      bottom: -50px;
      left: 0;
      right: 0;
      background-color: #B07E49;
      height: 1px;
      opacity: 0;
      transition: all .3s; }
      @media screen and (max-width: 64em) {
        .footer a:not(:first-child)::after {
          display: none; } }
    @media screen and (max-width: 64em) {
      .footer a:hover {
        color: #B07E49; } }
    .footer a:hover::after {
      bottom: -10px;
      height: 3px;
      opacity: 1; }

.filters .filter, .filters--stats .filter {
  margin: 0 70px 30px; }

.filters--stats .filter {
  margin: 0 50px 30px; }

.filter-views {
  background-color: #120E0C;
  color: #B07E49;
  font-size: 19px;
  box-shadow: inset 0 0 20px black;
  padding: 40px 0 10px;
  display: none; }
  .filter-views .filter-view, .filter-views .filter-view-space, .filter-views .filter-view-center, .filter-views .filter-view-middle {
    width: 1088px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .filter-views .filter-view-space {
      justify-content: space-between; }
    .filter-views .filter-view-center {
      justify-content: center; }
    .filter-views .filter-view-middle {
      justify-content: space-between; }
      .filter-views .filter-view-middle-block {
        max-width: 45%;
        flex: 0 0 45%; }
      .filter-views .filter-view-middle .title::after {
        display: none; }
  .filter-views .title {
    font-size: 33px;
    font-family: "riffic-bold", "Arial", sans-serif;
    position: relative;
    margin: 30px auto 50px;
    color: #FFFFFF;
    width: 100%;
    flex: 0 0 100%; }
    .filter-views .title span {
      background-color: #120E0C;
      padding: 0 20px;
      position: relative;
      z-index: 1; }
    .filter-views .title::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 1px;
      background-color: #2C1D10; }
  .filter-views .filter-content {
    width: 450px;
    max-width: 100%;
    margin-bottom: 65px; }
    .filter-views .filter-content h2 {
      color: #FFFFFF; }
    .filter-views .filter-content p {
      margin: 0; }
  .filter-views.is-active {
    display: block; }

.map {
  padding: 40px 0;
  width: 1086px;
  max-width: 100%;
  margin: 0 auto; }
  @media screen and (max-width: 64em) {
    .map {
      display: none; } }
  .map h2 {
    font-size: 46px;
    font-family: "riffic-bold", "Arial", sans-serif;
    color: #FFFFFF; }
  .map .world-map {
    margin: 45px auto;
    position: relative; }
    .map .world-map .image-holder {
      width: 1086px;
      height: 450px;
      overflow: hidden; }
      .map .world-map .image-holder .img-frame {
        background: url("../images/pattern.png");
        background-size: 240px; }
      .map .world-map .image-holder .left,
      .map .world-map .image-holder .right {
        width: 59px;
        position: absolute;
        top: -40px;
        bottom: -23px;
        z-index: 1; }
      .map .world-map .image-holder .left {
        left: -4px; }
      .map .world-map .image-holder .right {
        right: -4px; }
    .map .world-map .zoom {
      background-color: #2C1D10;
      width: 50px;
      height: 150px;
      position: absolute;
      bottom: 25px;
      right: 80px;
      border-radius: 26px; }
      .map .world-map .zoom span {
        height: 50px;
        width: 50px;
        display: block;
        position: relative;
        cursor: pointer; }
        .map .world-map .zoom span svg {
          z-index: 5;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
          .map .world-map .zoom span svg g {
            transition: all .3s;
            stroke: #B07E49; }
        .map .world-map .zoom span::after {
          content: "";
          transition: all .3s;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 0;
          height: 0;
          transform: translate(-50%, -50%);
          background-color: #B07E49;
          opacity: 0;
          z-index: 4;
          border-radius: 50%; }
        .map .world-map .zoom span:hover svg g, .map .world-map .zoom span:focus svg g {
          stroke: #FFFFFF; }
        .map .world-map .zoom span:hover::after, .map .world-map .zoom span:focus::after {
          width: 40px;
          height: 40px;
          opacity: 1; }

.pictures h2 {
  font-size: 46px;
  color: #FFFFFF;
  font-family: "riffic-bold", "Arial", sans-serif; }

.stat-holder {
  width: 1086px;
  max-width: 100%;
  margin: 0 auto; }
  .stat-holder .stat {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center; }
    .stat-holder .stat h4 {
      color: #FFFFFF;
      font-size: 21px;
      font-family: "riffic-bold", "Arial", sans-serif;
      margin-bottom: 20px;
      max-width: 100%;
      flex: 0 0 100%; }
    .stat-holder .stat .stat-frame-title {
      max-width: 370px;
      flex: 0 0 370px;
      margin: 0 40px 80px; }
    .stat-holder .stat .stat-frame {
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      padding: 30px 25px 0; }
      .stat-holder .stat .stat-frame::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #17120F;
        border: 6px solid #875A36;
        z-index: 2; }
      .stat-holder .stat .stat-frame::after {
        content: "";
        position: absolute;
        top: -6px;
        bottom: -6px;
        left: -6px;
        right: -6px;
        background: #2C1C10;
        z-index: 1; }
      .stat-holder .stat .stat-frame .stat-player {
        max-width: 62px;
        flex: 0 0 62px;
        margin-bottom: -35px;
        position: relative;
        z-index: 4; }
        .stat-holder .stat .stat-frame .stat-player .max {
          background: #0C0A08;
          position: relative;
          border-radius: 9px;
          height: 220px;
          width: 46px;
          margin: 0 auto; }
          .stat-holder .stat .stat-frame .stat-player .max .current {
            position: absolute;
            border-radius: 9px;
            bottom: 0;
            left: 0;
            right: 0;
            height: 0; }
        .stat-holder .stat .stat-frame .stat-player .amount {
          font-size: 18px;
          font-family: "riffic-bold", "Arial", sans-serif;
          color: #FFFFFF;
          margin: 20px auto; }
        .stat-holder .stat .stat-frame .stat-player .player svg {
          width: 62px;
          height: 73px; }
        .stat-holder .stat .stat-frame .stat-player.player1 .max .current {
          background-image: linear-gradient(#5A7A8E, #2E4556); }
        .stat-holder .stat .stat-frame .stat-player.player2 .max .current {
          background-image: linear-gradient(#ED9A1B, #8C3A21); }
        .stat-holder .stat .stat-frame .stat-player.player3 .max .current {
          background-image: linear-gradient(#5F5F5F, #222222); }
        .stat-holder .stat .stat-frame .stat-player.player4 .max .current {
          background-image: linear-gradient(#6CA159, #305427); }

.gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 60px;
  width: 1086px;
  max-width: 100%;
  margin: 0 auto; }
  .gallery-item {
    flex: 0 0 calc(33.33% - 40px);
    max-width: calc(33.33% - 40px);
    position: relative;
    margin: 20px; }
    .gallery-item img {
      border: 13px solid #17120F;
      position: relative;
      z-index: 10;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .gallery-item::after {
      content: "";
      position: absolute;
      z-index: 1;
      top: -10px;
      bottom: -10px;
      left: -8px;
      right: -8px;
      background-color: #875A36;
      border: 5px solid #2C1C10; }

/**
 * Pages
 */
.home .frame--left > p {
  text-align: left; }
  .home .frame--left > p.intro {
    text-align: center; }
