/**
 * Container
 */

.container {
  max-width: 1300px;
  margin: 0 auto;
}

.story-holder {
  padding: 70px 0;
  max-width: 100%;

  @media screen and (max-width: $m) {
    padding: 40px 0;
  }

  @media screen and (max-width: $s) {
    padding: 25px 0;
  }

  @media screen and (max-width: $xs) {
    padding: 20px 0;
  }

  .top-btn {
    width: 880px;
    max-width: 100%;
    margin: -30px auto 0;
    text-align: left;

    .btn--back {
      margin-top: 0;
    }

    & + .frame {
      margin: 115px auto 10px;
    }
  }
}
