.home {
  .frame {
    &--left {
      & > p {
        text-align: left;

        &.intro {
          text-align: center;
        }
      }
    }
  }
}

.some {
  h2 {
    @extend h1;
  }
}
