.footer {
  padding: 30px 0;
  background-color: $black;
  box-shadow: inset 0 0 20px black;

  .footer-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    width: 1088px;
    max-width: 100%;
    margin: 0 auto;

    @media screen and (max-width: $m) {
      display: block;
    }
  }

  svg {
    width: 201px;
    height: 81px;
  }

  a {
    font-size: 18px;
    color: $white;
    text-decoration: none;
    font-family: $font-alt;
    position: relative;

    @media screen and (max-width: $m) {
      display: block;
    }

    &:not(:first-child) {
      &::after {
        content: "";
        position: absolute;
        bottom: -50px;
        left: 0;
        right: 0;
        background-color: #B07E49;
        height: 1px;
        opacity: 0;
        transition: all .3s;

        @media screen and (max-width: $m) {
          display: none;
        }
      }
    }

    &:hover {
      @media screen and (max-width: $m) {
        color: #B07E49;
      }

      &::after {
        bottom: -10px;
        height: 3px;
        opacity: 1;
      }
    }
  }
}
