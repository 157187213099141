.heroes {
  width: 1060px;
  max-width: 100%;
  margin: 0 auto;
  padding: 40px 0;

  h2 {
    color: $white;
    font-size: 46px;
    font-family: $font-alt;
    margin-bottom: 30px;
  }

  .heroes-overview {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .hero {
      max-width: 172px;
      flex: 0 0 172px;
      text-align: center;
    }

    h4 {
      color: $white;
      font-size: 21px;
      margin-top: 20px;
      margin-bottom: 0;
    }

    p {
      color: #B07E49;
      font-size: 19px;
      margin-top: 0;
    }
  }
}
