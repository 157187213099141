/**
 * Normalise
 */

body {
  background-color: $bg;
  font-family: $font;
  text-align: center;
}

h1,
h2,
p,
a {
  margin: 20px auto;
}

ol,
ul {
  list-style-type: none;
}

h1 {
  font-size: 46px;
  color: $white;
  font-family: $font-alt;

  @media screen and (max-width: $m) {
    font-size: 40px;
  }

  @media screen and (max-width: $s) {
    font-size: 36px;
  }

  @media screen and (max-width: $xs) {
    font-size: 30px;
  }
}

h4 {
  color: #B07E49;
  font-size: 21px;
  font-family: $font-alt;
  margin-bottom: 0;
}
