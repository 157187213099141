.gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 60px;
  width: 1086px;
  max-width: 100%;
  margin: 0 auto;

  &-item {
    flex: 0 0 calc(33.33% - 40px);
    max-width: calc(33.33% - 40px);
    position: relative;
    margin: 20px;

    img {
      border: 13px solid #17120F;
      position: relative;
      z-index: 10;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      top: -10px;
      bottom: -10px;
      left: -8px;
      right: -8px;
      background-color: #875A36;
      border: 5px solid #2C1C10;
    }
  }
}
