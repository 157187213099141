.intro {
  width: 620px;
  max-width: 100%;
  color: $intro;
  font-size: 20px;
  font-family: $font;
  font-style: italic;
  line-height: 30px;
  font-weight: 300;
  max-width: 90%;

  @media screen and (max-width: $s) {
    font-size: 17px;
    line-height: 22px;
  }
}
