.stat-holder {
  width: 1086px;
  max-width: 100%;
  margin: 0 auto;

  .stat {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    h4 {
      color: $white;
      font-size: 21px;
      font-family: $font-alt;
      margin-bottom: 20px;
      max-width: 100%;
      flex: 0 0 100%;
    }

    .stat-frame-title {
      max-width: 370px;
      flex: 0 0 370px;
      margin: 0 40px 80px;
    }

    .stat-frame {
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      padding: 30px 25px 0;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #17120F;
        border: 6px solid #875A36;
        z-index: 2;
      }

      &::after {
        content: "";
        position: absolute;
        top: -6px;
        bottom: -6px;
        left: -6px;
        right: -6px;
        background: #2C1C10;
        z-index: 1;
      }

      .stat-player {
        max-width: 62px;
        flex: 0 0 62px;
        margin-bottom: -35px;
        position: relative;
        z-index: 4;

        .max {
          background: #0C0A08;
          position: relative;
          border-radius: 9px;
          height: 220px;
          width: 46px;
          margin: 0 auto;

          .current {
            position: absolute;
            border-radius: 9px;
            bottom: 0;
            left: 0;
            right: 0;
            height: 0;
          }
        }

        .amount {
          font-size: 18px;
          font-family: $font-alt;
          color: $white;
          margin: 20px auto;
        }

        .player {
          svg {
            width: 62px;
            height: 73px;
          }
        }

        &.player1 { .max { .current { background-image: linear-gradient(#5A7A8E, #2E4556); }}}
        &.player2 { .max { .current { background-image: linear-gradient(#ED9A1B, #8C3A21); }}}
        &.player3 { .max { .current { background-image: linear-gradient(#5F5F5F, #222222); }}}
        &.player4 { .max { .current { background-image: linear-gradient(#6CA159, #305427); }}}
      }
    }
  }
}
