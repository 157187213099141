.btn {
  padding: 13px 25px;
  font-family: $font-alt;
  border-radius: 25px;
  border: none;
  transition: $animate;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  margin-bottom: 0;
  font-size: 16px;

  &-group {
    .btn {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  &--primary {
    @extend .btn;
    background-color: #2C1D10;
    color: $white;

    &:hover,
    &:focus,
    &.is-active {
      background-color: #B07E49;
      color: $white;
    }
  }

  &--secondary {
    @extend .btn;
    background-color: #2C1D10;
    color: #B07E49;

    &:hover,
    &:focus,
    &.is-active {
      background-color: #B07E49;
      color: $white;
    }
  }

  &--icon {
    @extend .btn;
    background-color: #2C1D10;
    color: $white;
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      g {
        stroke: $white;
      }
    }

    &:hover,
    &:focus {
      background-color: #B07E49;
      color: $white;
    }
  }

  &--back {
    @extend .btn--icon;
    padding-left: 55px;

    svg {
      left: 25px;
    }
  }

  &--next {
    @extend .btn--icon;
    padding-right: 55px;

    svg {
      right: 25px;
    }
  }
}
