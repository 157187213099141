.frame {
  width: 830px;
  max-width: 80%;
  margin: 145px auto 10px;
  position: relative;
  text-align: center;

  .intro {
    color: #5B3D23;
    font-size: 21px;
    line-height: 30px;
    margin-bottom: 70px;
    font-weight: 300;
  }

  .icon-holder {
    position: relative;
    z-index: 6;

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .icon-holder-opt {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 200px;
      height: 220px;
      cursor: pointer;

      span {
        display: block;
        color: $white;
        width: 199px;
        height: 222px;
        position: relative;

        h5 {
          font-size: 18px;
          position: absolute;
          z-index: 10;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 90px;
        }
      }

      svg {
        width: 187px;
        height: 220px;
      }

      &:nth-child(1) {
        left: calc(50% - 110px);
      }

      &:nth-child(2) {
        left: calc(50% + 110px);
      }

      &.is-active {
        span {
          display: none;
        }
      }
    }
  }

  .frame-content {
    position: relative;
    z-index: 5;
    background-color: $bg-light;
    padding: 115px 100px 32px;
    border: 12px solid $bg;

    @media screen and (max-width: $m) {
      padding: 115px 30px 32px;
    }

    &--alt {
      @extend .frame-content;
      text-align: center;

      @media screen and (max-width: $m) {
        padding: 30px 20px 32px !important; /* stylelint-disable-line declaration-no-important */
      }

      @media screen and (max-width: $s) {
        padding: 30px 10px 32px !important; /* stylelint-disable-line declaration-no-important */
      }

      h2 {
        @media screen and (max-width: $m) {
          font-size: 35px;
        }

        @media screen and (max-width: $s) {
          font-size: 30px;
        }
      }
    }

    &--left {
      @extend .frame-content;
      display: none;

      &.is-active {
        display: block;
      }

      .img-holder {
        margin: 70px auto;
        text-align: center;
      }

      p:not([class]) {
        text-align: left;
      }
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: -10px;
    left: -15px;
    right: -15px;
    bottom: -10px;
    background-color: #5B3D23;
    z-index: 4;
    border: 6px solid #875A36;
  }

  &::before {
    content: "";
    position: absolute;
    top: -20px;
    left: -25px;
    right: -25px;
    bottom: -20px;
    background-color: #2C1C10;
    z-index: 3;
  }

  p {
    line-height: 27px;
    font-size: 19px;
    color: $bg;
    font-family: $font;
  }

  &--left {
    & > p {
      text-align: left;

      &.intro {
        text-align: center;
      }
    }
  }

  &--chronicles {
    @extend .frame;
    width: 1006px;
    margin-top: -20px;

    .frame-content {
      padding: 50px 50px 32px;
    }

    .tbd {
      pointer-events: none;
      opacity: .4;
    }

    h2 {
      font-size: 46px;
      color: #2C1C10;
      font-family: $font-alt;
      margin-bottom: 30px;
      margin-top: 0;
    }

    li {
      text-align: left;

      a {
        border-radius: 12px;
        background-color: #DABB91;
        font-family: $font-alt;
        display: block;
        margin-bottom: 7px;
        margin-top: 7px;
        color: #5B3D23;
        font-size: 21px;
        text-decoration: none;
        padding: 14px 60px 14px 20px;
        position: relative;
        transition: all .3s;

        span {
          position: relative;
          z-index: 2;
        }

        .arrow {
          display: block;
          width: 55px;
          position: absolute;
          z-index: 1;
          top: 0;
          bottom: 0;
          right: 0;
          border-radius: 0 12px 12px 0;
          background: #5B3D23;
          transition: all .3s;

          svg {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
          }
        }

        &:hover,
        &:focus {
          color: $white;

          .arrow {
            border-radius: 12px;
            width: 100%;
          }
        }
      }
    }
  }
}
