.squads {
  background-color: $black;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-family: $font-alt;
  padding: 55px 0;
  box-shadow: inset 0 0 20px black;

  @media screen and (max-width: $s) {
    padding: 35px 0;
  }

  @media screen and (max-width: 400px) {
    padding: 25px 0 0;
  }

  .squad {
    font-size: 30px;
    margin: 0;
    color: $white;
    text-decoration: none;
    transition: all .3s;

    @media screen and (max-width: $m) {
      width: 50%;
      display: none;
    }

    @media screen and (max-width: $s) {
      font-size: 22px;
    }

    @media screen and (max-width: $xs) {
      font-size: 16px;
    }

    @media screen and (max-width: 400px) {
      p {
        display: none;
      }
    }

    svg {
      transition: all .2s;

      @media screen and (max-width: $s) {
        max-width: 80%;
        height: 280px;
      }

      @media screen and (max-width: $xs) {
        height: 200px;
      }
    }

    &-griffon {
      @media screen and (max-width: $m) {
        display: block;
      }

      &:hover {
        position: relative;
        text-shadow: 0 0 30px #0AB5E1;

        svg {
          transform: scale(1.1);
        }
      }
    }

    &-hellhound {
      @media screen and (max-width: $m) {
        display: block;
      }

      &:hover {
        position: relative;
        text-shadow: 0 0 30px #F7B662;

        svg {
          transform: scale(1.1);
        }
      }
    }

    p {
      margin: 0;
    }

    &:nth-child(2) {
      font-size: 33px;
      width: 250px;
    }
  }
}
