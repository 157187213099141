.filters {
  .filter {
    margin: 0 70px 30px;
  }

  &--stats {
    @extend .filters;

    .filter {
      margin: 0 50px 30px;
    }
  }
}

.filter-views {
  background-color: $black;
  color: #B07E49;
  font-size: 19px;
  box-shadow: inset 0 0 20px black;
  padding: 40px 0 10px;
  display: none;

  .filter-view {
    width: 1088px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &-space {
      @extend .filter-view;
      justify-content: space-between;
    }

    &-center {
      @extend .filter-view;
      justify-content: center;
    }

    &-middle {
      @extend .filter-view;
      justify-content: space-between;

      &-block {
        max-width: 45%;
        flex: 0 0 45%;
      }

      .title {
        &::after {
          display: none;
        }
      }
    }
  }

  .title {
    font-size: 33px;
    font-family: $font-alt;
    position: relative;
    margin: 30px auto 50px;
    color: $white;
    width: 100%;
    flex: 0 0 100%;

    span {
      background-color: $black;
      padding: 0 20px;
      position: relative;
      z-index: 1;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 1px;
      background-color: #2C1D10;
    }
  }

  .filter-content {
    width: 450px;
    max-width: 100%;
    margin-bottom: 65px;

    h2 {
      color: $white;
    }

    p {
      margin: 0;
    }
  }

  &.is-active {
    display: block;
  }
}
