/**
 * Main menu
 */

.main-menu {
  background-color: $white;
}

.top {
  height: 65px;
  position: relative;

  a {
    position: relative;
    top: 22px;

    svg {
      @media screen and (max-width: $m) {
        width: 200px;
      }
      @media screen and (max-width: $s) {
        width: 170px;
      }
    }
  }

  .logo {
    position: absolute;
    bottom: 50%;
  }
}
