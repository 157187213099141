.map {
  padding: 40px 0;
  width: 1086px;
  max-width: 100%;
  margin: 0 auto;

  @media screen and (max-width: $m) {
    display: none;
  }

  h2 {
    font-size: 46px;
    font-family: $font-alt;
    color: $white;
  }

  .world-map {
    margin: 45px auto;
    position: relative;

    .image-holder {
      width: 1086px;
      height: 450px;
      overflow: hidden;

      .img-frame {
        background: url("../images/pattern.png");
        background-size: 240px;
      }

      .left,
      .right {
        width: 59px;
        position: absolute;
        top: -40px;
        bottom: -23px;
        z-index: 1;
      }

      .left {
        left: -4px;
      }

      .right {
        right: -4px;
      }
    }

    .zoom {
      background-color: #2C1D10;
      width: 50px;
      height: 150px;
      position: absolute;
      bottom: 25px;
      right: 80px;
      border-radius: 26px;

      span {
        height: 50px;
        width: 50px;
        display: block;
        position: relative;
        cursor: pointer;

        svg {
          z-index: 5;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          g {
            transition: all .3s;
            stroke: #B07E49;
          }
        }

        &::after {
          content: "";
          transition: all .3s;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 0;
          height: 0;
          transform: translate(-50%, -50%);
          background-color: #B07E49;
          opacity: 0;
          z-index: 4;
          border-radius: 50%;
        }

        &:hover,
        &:focus {
          svg {
            g {
              stroke: $white;
            }
          }

          &::after {
            width: 40px;
            height: 40px;
            opacity: 1;
          }
        }
      }
    }
  }
}
